const postToFunction = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (e) {
    console.log(e);
    return {
      error: "Something went wrong. Please try again.",
    };
  }
};

export default postToFunction;
