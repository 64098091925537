import React from "react";
import styled from "styled-components";
import { Body } from "../components/common/typography/";
import { transparentize } from "polished";
import postToFunction from "./postToFunction";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const initialFormValues = [
  {
    key: "fname",
    label: "First Name",
    value: "",
    error: null,
    validator: /^.+$/,
    failedReason: "This must not be blank",
  },
  {
    key: "lname",
    label: "Last Name",
    value: "",
    error: null,
    validator: /^.+$/,
    failedReason: "This must not be blank",
  },
  {
    key: "email",
    label: "Email Address",
    value: "",
    error: null,
    validator: emailRegex,
    failedReason: "That doesn't look like a valid email address",
    type: "email",
  },
  {
    key: "moment",
    label: "Your Special Moment",
    value: "",
    error: null,
    validator: /^.+$/,
    failedReason: "This must not be blank",
  },
];

export const FormField = ({ field, onChange, narrow, noMargin }) => {
  const [focused, setFocused] = React.useState(false);
  return (
    <Label htmlFor={field.key} noMargin={noMargin}>
      <LabelText shrink={focused || Boolean(field.value)}>
        {field.label}
      </LabelText>
      <Input
        type={field.type || "text"}
        id={field.key}
        name={field.key}
        value={field.value}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        narrow={narrow}
      />
      <Error content={field.error}>{field.error}</Error>
    </Label>
  );
};

export const Checkbox = ({ name, label, checked, onChange, narrow }) => {
  return (
    <CheckboxLabel htmlFor={name} narrow={narrow}>
      <CheckboxInput
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <CheckboxLabelText>{label}</CheckboxLabelText>
    </CheckboxLabel>
  );
};

export const Label = styled.label`
  margin-bottom: ${({ theme, noMargin }) => (noMargin ? 0 : theme.spacing.lg)};
`;

export const LabelText = styled(Body)`
  position: relative;
  color: ${({ theme }) => transparentize(0.5, theme.colour.fullWhite)};
  max-width: max-content;
  transform-origin: left;
  transition: top 0.5s ease 0s, transform 0.5s ease 0s;

  ${({ shrink }) =>
    shrink
      ? `
        top: 0px;
        transform: scale(0.7);
      `
      : `
      top: 25px;
      cursor: text;
      `}
`;

export const Input = styled.input`
  display: block;
  width: ${({ narrow }) => (narrow ? "330px" : "400px")};
  max-width: 90vw;
  padding-bottom: 10px;
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.5, theme.colour.fullWhite)};
  color: ${({ theme }) => theme.colour.fullWhite};
`;

export const CheckboxLabel = styled.label`
  margin-bottom: 0;
  width: ${({ narrow }) => (narrow ? "330px" : "400px")};
  max-width: 90vw;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  position: relative;
`;

export const CheckboxLabelText = styled(Body)`
  color: ${({ theme }) => transparentize(0.5, theme.colour.fullWhite)};
  max-width: 340px;
  margin: 0;
  display: inline;
  justify-self: end;
  text-align: left;
  grid-column: 2 / span 1;
`;

export const CheckboxInput = styled.input`
  appearance: none;
  width: 25px;
  height: 25px;
  border: 1px solid
    ${({ theme }) => transparentize(0.5, theme.colour.fullWhite)};
  border-radius: 1px;
  outline: none;
  background-color: transparent;
  grid-column: 1 / span 1;

  &:checked + ${CheckboxLabelText}::before {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAIRlWElmTU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAABIAAAAAQAAAEgAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAABmgAwAEAAAAAQAAABkAAAAAq8n6XQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAglJREFUSA29VD1LA0EQzX3lEgjYWVmIiCBYWFjZWKj/wMLGwsbCQmwsrBRBBH+KjWglBC0sbKz8JSJojNEkvjeZOdbzEhNdPNjM7Me8N/N2NqXSP3yBR47IwWo7vjc3n2zsDVmBpIIwDE+DIKhXKpVJXQ99EZUJFEXROgi6HCBrYr7miyghUK1WGwfwo5I8qb1Tkj/JxjsQOZD1uQI31XbjOF5UErcZdGl4IzIlSbKpwO+wr/RBcOKDQLKrVqsTkOlZSRq0mD84eQ68eEpBoHxbWrzJdKUEIhMIuqhsQQ9JpRaQt66GRUQpAwC4rQSUqUUfMh1yD99QBASfl+O9H6tIugnvYAokoj/AxeLy7wvOO0s9N9MPgRfMDED7eop7WYUAvOE+htwD/XK5PKdn+7asZVpCwKUC2MM60mCRCZrv6v4bLAeT2dMzUqn634xlOYYgt1vaCnLMCGQ7i3mHaxhy2ajq1kHL1HDWvrhGtKQgBiSgIDgA4LXuSQXwW2mazijKwCpcJtETXbICCUQuABGw48xZnXQT1nY0eGA3uQTmi/YgWnWASfSBQQKroq4BP0pkwHmbEQHUKmL2JOJFN5y/877dlActmosEqGjZIXqhjw7b0oCRZSoikoqwwWaQrkMVZ3qQMmWtXxQ8ypplO43u2kCgEf/6LvqR53X3TmDEBOZb8CaRAXuzn3qucLgO5GK/AAAAAElFTkSuQmCC");
    display: block;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 25px;
    left: 0;
    top: 50%;
    transform: translateY(-25%);
  }
`;

export const Error = styled(Body)`
  color: ${({ theme }) => theme.colour.sigPeach};
  opacity: ${({ content }) => (content ? 1 : 0)};
  transition: opacity 0.5s linear;
`;

export const changeFormValue = (key, setFormValues, setFetchError) => (
  event
) => {
  setFetchError(null);
  const newValue =
    key === "email"
      ? event?.target?.value?.toLowerCase()
      : event?.target?.value;
  setFormValues((oldValues) =>
    oldValues.map((field) => {
      if (field.key !== key) return field;
      return { ...field, value: newValue, error: null };
    })
  );
};

const setFieldError = (key, setFormValues) => (message) => {
  setFormValues((oldValues) =>
    oldValues.map((field) => {
      if (field.key !== key) return field;
      return { ...field, error: message };
    })
  );
};

const validate = (formValues, setFormValues) => {
  let formHasError = false;
  formValues.forEach((field) => {
    const setError = setFieldError(field.key, setFormValues);
    if (!field.validator.test(field.value?.trim())) {
      setError(field.failedReason);
      formHasError = true;
    }
  });
  return !formHasError;
};

export const submitForm = async ({
  functionUrl,
  successCallback,
  extraData = {},
  formValues,
  setFormValues,
  setFetchError,
  setFormIsSaving,
}) => {
  setFetchError(null);
  const formIsValid = validate(formValues, setFormValues);
  if (!formIsValid) return null;
  setFormIsSaving(true);
  const response = await postToFunction(functionUrl, {
    ...formValues.reduce(
      (accumulator, field) => ({
        ...accumulator,
        [field.key]: field.value?.trim(),
      }),
      {}
    ),
    ...extraData,
  });
  if (response.error) {
    setFetchError(response.error);
    setFormIsSaving(false);
  } else {
    successCallback(response);
  }
};

export const anyValueEmpty = (formValues) =>
  Boolean(formValues.map((field) => field.value).filter((val) => !val).length);
